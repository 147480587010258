<template>
  <div>
    <p>
      Shenzhen Meya Development Co., Ltd. and its affiliates (hereinafter
      "Meya", "We" and "ours") know the importance of your private information
      and will respect your privacy.Please read this Privacy Policy (hereinafter
      "This Policy") carefully before submitting your personal data to MAIA.This
      policy applies to American Asia websites, products, devices or
      applications (collectively our "Services") that display, or are linked to
      this Privacy Policy, including, but not limited to, American Asia Mall,
      American Asia official website, American Asia official account and other
      services.All text, pictures, user interfaces, visual interfaces, photos,
      trademarks, logos, sounds, music, artwork, and computer codes
      (collectively the "Content"), including, but not limited to, the design,
      structure, selection, collocation, expression, appearance, style and
      arrangement, are owned, controlled or authorized by American Asia and
      protected by commercial appearance, copyright, patent and trademark laws
      and various other intellectual property and anti-unfair competition
      laws.This policy describes how America will handle your data (whether
      personal or not), but may not involve all possible data processing
      situations.Information on the specific data of a product or service may be
      provided by MayA in the supplementary policy or at the notice of the data
      collection and provide you with an independent choice of consent and
      collection and use with your express consent.AIA has the right to decide
      at any time to change, modify, add, or delete portions of this Privacy
      Policy.You can check this Privacy Policy to check for whether changes have
      occurred.In the course of reading this policy, if you do not agree with
      this policy or any of it, you should immediately stop using the relevant
      services provided by Asia.If you use the relevant services provided by
      AIA, it means that you recognize the whole content of this statement.The
      purpose of this policy is to help you understand the following:
    </p>
    <ul>
      <li>1. How Meiya collects and uses your personal data</li>
      <li>2. How do America and Asia use Cookie and similar technologies</li>
      <li>3. How to disclose your personal data</li>
      <li>4. How to access or modify your personal data</li>
      <li>5. Protection of the personal information of minors</li>
      <li>6. How America America preserves and protects your personal data</li>
      <li>7.Third-party providers and their services</li>
      <li>8.How to update this policy</li>
    </ul>
    <h1>I. How Meiya collects and uses your personal data</h1>
    <p>
      Personal data is information that is personally identifiable when used
      alone or in combination with other information.Such data will be submitted
      to us directly when you use our website, products or services and interact
      with us, for example:
    </p>

    <ul>
      <li>
        1. Information filled in and submitted when you register as a member and
        when using relevant services provided by Meiya or other platforms, such
        as your name, gender, date, month, date of birth, ID number, phone
        number, email, address, avatar, nickname, location, etc.
      </li>
      <li>
        2. When you choose services like purchase, delivery, and installation,
        we may ask for your name, contact information, delivery address and
        billing address, and bank card information in order to process your
        order.
      </li>
      <li>
        3. When you download or use the client software, Maya may read
        information related to your device, including but not limited to
        hardware models, IMEI number and other unique device identifiers, MAC
        address, IP address, operating system version, and settings for the
        device used to access the service.
      </li>
      <li>
        4. When you use Meiya Iot of Things equipment, Meiya may read your use
        without notifying you, including but not limited to the equipment model,
        equipment operation status, equipment function use frequency, log
        information of the Internet of Things equipment, measurement data or
        other use information uploaded by the equipment, some equipment built-in
        camera may photograph the use of the equipment, etc.
      </li>
      <li>
        5. When you use the Meiya services or visit the Meiya website, Meiya
        automatically receives and records information on your browser and
        computer, including but not limited to your IP address, the type of
        browser, language used, date and time of access, information on software
        and hardware features, and web records you need.
      </li>
      <li>
        6. Location Information (for specific services / functions only): When
        you download or use client software or access web pages, and use certain
        AIA services, IoT devices, AIA may read various information related to
        your location, such as the GPS signal of the device or nearby WiFi
        access points and mobile phone signal towers, country code, city code,
        mobile network code, cell identification, latitude and longitude
        information, and language settings that may be transmitted to us when
        you use certain services.
      </li>
    </ul>

    <p>
      Where legally allows, we will also obtain data from public and commercial
      third-party sources, such as supporting our services by purchasing
      statistics from other companies, obtaining order data from third-party
      e-commerce platforms, questionnaire response information sent to us when
      participating in the questionnaire, participation in the user experience
      plan and relevant information we collect when you interact with us and
      your American-Asia partners.The data we collect depends on how you
      interact with Meiya, including the website visited or the products and
      services used, such as name, gender, business name, position, address,
      email, phone number, login information (account and password), photos, ID
      information, etc.In some cases, you can choose not to provide personal
      data to America, but if you choose not to provide it, it may not be able
      to provide you with relevant products or services, or respond to or solve
      the problems you encounter.We may use your personal data for these
      purposes:
    </p>

    <ul>
      <li>
        1. Create an account, and use the personal information collected when
        creating the account through the website or the mobile device to
        establish the user's personal account and data page.
      </li>
      <li>
        2. Implement your transaction or service request, such as: fulfillment
        of orders, information related to e-commerce orders can be used to
        process order orders and related after-sales service, including customer
        support and re-shipment.Recipient information, including name, address,
        phone number, and postal code will be used for delivery.Email addresses
        are used to send package tracking information to users.A list of
        purchased items is used to print invoices and allow the customer to
        identify the items in the package.When you use customer service
        telephone, WeChat public number and other self-service platform requires
        Asia to provide you with product installation, maintenance, consulting
        services, according to your different demands, you need to provide us
        with your name, address, contact information, products to install or
        repair the information, so as to provide you with timely required
        services.
      </li>
      <li>
        3. Activate or verify the product or service, control and use the
        equipment.You control part of your equipment and need to log in to the
        account with the APP, and complete the distribution network and binding
        process of the equipment normally.Once you complete these processes, you
        can remotely control or LAN control the device through APP.Some
        equipment will need to be activated before its use.The activation
        process requires using relevant equipment information, including: MAC
        address, machine coding, WIFI password, etc.If the activation fails, we
        may read the relevant device information and log information to assess
        the cause of the activation failure.At the same time, you control your
        networked device may need to use information such as a user name /
        password.
      </li>
      <li>
        4. Provide location-based services.When using certain services, we or a
        third-party service provider may use location information to provide the
        right version of the service and provide the accurate location to
        achieve the best possible user experience, such as reading your location
        about your nearest warehouse and predicting delivery time, or using
        different programs to ensure the normal implementation of device
        functions at different altitudes and regions.
      </li>
      <li>
        5. Send you information about products and services that you may be
        interested in, including but not limited to inviting you to participate
        in American-Asian activities (e. g., promotion), market surveys or
        satisfaction surveys, or send you marketing messages, if you participate
        in a lottery, competition or similar promotion, we will use the
        information you provide to manage such activities, if you win, may
        desensitize your mobile phone number or nickname to the winning list and
        publish.If the event is jointly promoted by us and a third party, we may
        share the personal information necessary to complete the event (the
        number of users, the list of the winners) so that the third party can
        award you prizes or serve you in a timely manner.
      </li>
      <li>
        6. Send you important notifications, such as operating system or
        application updates or installations.
      </li>
      <li>
        7. Provide you with personalized user experience and personalized
        content, such as obtaining additional product after-sales service.
      </li>
      <li>
        8. Allows you to use third-party features.Some of the products we offer
        are operated by third-party service providers.Therefore, we need to
        share your information with third parties to enable you to experience
        the full functionality.For example, when you use our service to search
        for recipes, audio, video, ingredients encyclopedia, etc., we may need
        to pass on your search request to third-party service providers.
      </li>
      <li>
        9. Collect user use feedback.The use feedback you provide can help to
        improve our services, device functions, etc.To track the feedback you
        choose to provide, you may contact you using the personal information
        you provide and keep records.
      </li>
      <li>
        10. Conduct internal audit, data analysis, and research to improve our
        products and services.
      </li>
      <li>
        11. Analyze business operational efficiency and measure market share.
      </li>
      <li>
        12. Synchronize, share and store data you upload or download and data
        required to perform upload and download.
      </li>
      <li>
        13. Follow and enforce applicable legal requirements, relevant industry
        standards or our policies.
      </li>
    </ul>

    <p>
      MeiYa Non-identifying data may also be collected and used.Non-identifiable
      data are data that cannot be used to identify an individual.For example,
      Asia collects aggregated statistics such as website visits, frequency of
      Internet of Things devices, etc.Meia collects this data to understand how
      users use their websites, products and services.In this way, Meiya can
      improve its services and better meet customer needs.Asia may collect, use,
      process, transfer, or disclose non-identifiable data for other purposes.We
      will do our best to isolate your personal and non-identifiable data and
      use both data separately.If personal data is mixed with non-identifying
      data, it is still treated as personal data processing.When Asia processes
      your personal data we follow the requirements of applicable law based on
      an appropriate legitimacy basis, including:
    </p>
    <ul>
      <li>
        1.Process your personal data when fulfilling the contract in response to
        your transaction or service request;
      </li>
      <li>2.Process your personal data based on your consent;</li>
      <li>
        3When contacting you, conducting marketing or market research to process
        our personal data to improve our products and services and performing
        and improving our damage prevention and anti-fraud programs we will be
        based on the legitimate interests of our or a third party.
      </li>
      <li>
        4.We may also process your personal data based on compliance and
        enforcement of legal obligations.
      </li>
    </ul>

    <h1>
      II、How Meiya uses Cookie and its similar technology
    </h1>
    <h2>1.Cookie</h2>
    <p>
      To ensure that the site works properly, we sometimes store small data
      files called Cookie on a computer or mobile device.Cookie is a plain text
      file for a web server stored on a computer or a mobile device.The content
      of a Cookie can only be retrieved or read by the server that created
      it.Each Cookie is unique to your web browser or mobile app.The Cookie
      typically contains identifiers, site names, and some numbers and
      characters.With Cookie, websites can store data such as user preferences
      or items in the basket.Meya enabled Cookie for the same purpose that most
      websites or Internet service providers used Cookie to improve the user
      experience.With Cookie, the site is able to remember the user's single
      visit (using session Cookie) or multiple visits (using permanent
      Cookie).With Cookie, the site is able to save settings, such as the
      language of a computer or mobile device, font size, and other browsing
      preferences.This means that users do not need to reconfigure their user
      preferences at each visit.
    </p>
    <h2>
      2.Website beacons and pixel tags
    </h2>
    <p>
      We will use other similar technologies such as website beacons and pixel
      labels on the website.For example, an email sent to you may contain a
      click URL linked to the content of the Meya website.If you click on the
      link, Meya will track this click to help us understand your product and
      service preferences and improve customer service.If you don't want your
      activities to be tracked in this way, you can unsubscribe from your
      American-Asia mailing list at any time.
    </p>
    <h2>
      3.Mobile analysis software
    </h2>
    <p>
      In APP, we use mobile analytics software to better understand the
      understanding of our APP features in your phone.This software may record
      the following information, such as how often you use the application,
      events within the application, cumulative usage, performance data, and
      where the application crashed.
    </p>
    <h2>
      4.Use of SDK
    </h2>

    <p>
      Huawei device management service SDK
    </p>
    <p>
      Purpose of collecting personal information: complete the end cloud
      authorization verification with Huawei cloud to ensure that your products
      always meet the requirements of harmonyos connect certification
    </p>
    <p>
      Type of personal data collected: device hardware identifier, device
      hardware parameters and system version information related to the device
    </p>
    <p>
      Third party SDK privacy statement:Click to view the details of
      <a
        href="https://device.harmonyos.com/cn/docs/devicepartner/DevicePartner-Guides/device-privacy-statement-0000001188494819"
        target="blank"
        >the statement of HUAWEI device management service and privacy
      </a>
    </p>

    <!-- <p>
      In order to ensure the stable operation and function realization of our
      client, so that you can use and enjoy more services and functions, our
      application will embed the SDK of the authorized partner or other similar
      applications.
      <a
        href="https://developer.huawei.com/consumer/en/doc/development/SmartDevicePartner-Guides/device-privacy-statement-0000001188494819"
        target="blank"
      >
        The statement of HUAWEI device management service and privacy .</a
      >
    </p> -->
    <!-- <p>
      Your use of our website means that you agree to use cookies, website
      beacons, pixel tags, SDK and mobile analysis software as described above.
    </p> -->
    <h1>
      Ⅲ、How to disclose and share your personal data
    </h1>
    <p>
      Your personal information will be kept strictly confidential and will only
      be shared with the data recipients in the categories listed below.In
      addition, we will only share your information with third parties outside
      with your consent and you will have the option of whether to allow us to
      share your information.
    </p>
    <h2>1.MeiYa associated company 。</h2>
    <p>
      To facilitate us to serve you and recommend information that you may be
      interested in or to protect the personal or property safety of AIA or
      other users or the public, your personal information may be shared between
      AIA affiliates.We will only share the necessary personal information and
      will again seek your authorization consent, if we share your personal
      sensitive information or if the affiliates change the use and processing
      purpose of the personal information.
    </p>
    <h2>2.An Authorized Partner.</h2>
    <p>
      We may share your information with trusted authorized partners.We may
      share your account information, equipment information, and location
      information with our authorized partners to ensure the smooth completion
      of the services provided for you.But we will only share your personal
      information for legitimate, legitimate, necessary, specific, explicit
      purposes and will only share the personal information necessary to provide
      the service.Our partners are not entitled to use the shared personal
      information for any other purpose.Currently, our authorized partners
      include the following types of authorized partners: advertising and
      analysis services.Dealers, suppliers, service providers, and other
      partners.Our dealers, suppliers, and service providers include
      institutions that provide technical services, telecommunications services,
      customer services, risk control services, audit services, legal services,
      financial institutions, e-commerce companies and platforms, offline
      stores, etc.We will sign confidentiality agreements with the companies,
      organizations, and individuals with which we share our personal
      information, and will require them in an appropriate manner to process
      their personal information in accordance with our instructions, this
      Privacy Policy, and any other relevant confidentiality and security
      measures.In terms of personal sensitive data use, we require third parties
      to adopt encryption technology to better protect user data.If it is found
      to violate the agreement, it will take effective measures or even
      terminate the cooperation.Compliance with the mandatory requirements of
      laws and regulations, court orders or government authorities, safeguarding
      the social public interest, or purposes reasonably necessary to protect
      the safety or legitimate rights and interests of our clients, American or
      American employees.Other parties who obtain your consent separately.In
      addition to the sharing situation as described in this Privacy Policy, we
      may also share your information with third parties when you agree to share
      or make a share request.
    </p>
    <h2>3.Information without consent is required</h2>
    <p>
      We may share anonymous information with third parties in aggregate form
      for business purposes; we may share general usage trends with our
      services, such as the number of customers in a certain group of customers
      purchasing certain products or engaging in certain transactions.According
      to relevant laws and regulations, we may share, transfer, and publicly
      disclose your personal information without obtaining your authorization
      under: Related to national security and national defense security; Related
      to public safety, public health, or major public interests; Related to
      criminal investigation, prosecution, trial and execution of judgment; To
      protect the major legal rights and interests of your life or other
      individuals, but it is difficult to get my consent; The personal
      information that you will disclose to the public by yourself; Personal
      information collected from the legally publicly disclosed information,
      such as legal news reports, government information disclosure and other
      channels. It is necessary to maintain the safe and stable operation of the
      product or service provided, such as the discovery and disposal of faults
      of the product or service. Other circumstances as stipulated by laws and
      regulations. According to the law, sharing, transfer of personal
      information, and ensure that the data receiver cannot recover and identify
      the personal information subject, does not belong to the external sharing,
      transfer and public disclosure of personal information, the preservation
      and processing of such data will not need to inform you and obtain your
      consent.If you do not agree that we provide your personal information to
      third parties under the above terms, please stop using our services
      immediately.
    </p>
    <h1>
      IV. How to access or modify your personal data
    </h1>
    <p>
      You should ensure that all personal data submitted are accurate.Asia will
      try to maintain the accuracy and integrity of personal data and update
      them in a timely manner.In the case of applicable law requirements, you
      may:
    </p>
    <ul>
      <li>1. Right to access the specific personal data we hold about you;</li>
      <li>2. Ask us to update or correct your inaccurate personal data;</li>
      <li>3. Refuse or restrict our use of your personal data;</li>
      <li>
        4. Ask us to delete your personal data or proactively cancel your
        account.
      </li>
    </ul>
    <p>
      If you want to exercise the relevant rights, please give feedback to us
      online through this email box, chenzhiyi@mpebedding.com.To be secure, we
      may ask you to verify your identity (like a written request or otherwise)
      before processing your request.We decline processing of the requests if we
      have reasonable grounds for finding that they are fraudulent, unfeasible,
      or detrimental to another's privacy rights.When you or we assist you in
      removing the relevant information, and we may not remove the corresponding
      information from the backup system immediately due to applicable legal and
      security technical limitations, we will securely store your personal
      information and restrict any further processing of it until the backup can
      be cleared or anonymized.Before deleting your personal data or account
      cancellation, please back up and properly keep the account information and
      other relevant data, and properly keep the transaction documents, bills
      and other materials of the products and services, so as not to fail to
      enjoy the relevant after-sales service or need to pay additional
      fees.After personal data deletion or account cancellation, you may not be
      able to continue using our services, and you will not be able to retrieve
      any content or information in the account and related to the account.You
      also have the right to revoke your consent at any time, when the
      applicable law requires, and when AIA processes your personal data based
      on your consent.However, the revocation consent will not affect the
      legality and validity of our handling of your personal data based on your
      consent before the revocation, nor will our handling of your personal data
      based on other appropriate legitimacy bases.
    </p>
    <h1>
      V. Protection of the personal information of minors
    </h1>
    <p>
      The products and services are mainly for adults.If you are a minor under
      the age of 14, you should read this guide accompanied by your guardian
      before using our products and / or services and should ensure that our
      services are used and informed to us with your consent.We will focus on
      protecting the personal information of minors in accordance with the
      relevant national laws and regulations.If your guardian does not agree
      that you may use our services in accordance with these guidelines or
      provide us with information, please terminate the use of our services
      immediately and notify us promptly.Where minor personal information is
      collected with the consent of the parent or legal guardian, we will only
      use or publicly disclose this information if necessary as permitted by law
      or with the express consent of the parent or guardian or to protect the
      minor.If you are the guardian of a minor, please contact us promptly with
      any questions about the use of our services or the user information it has
      provided to us.We will protect the confidentiality and security of
      juvenile user information in accordance with relevant national laws and
      regulations and these Guidelines.If we find ourselves collecting minor
      personal information without the prior consent of verifiable parents or
      legal guardians, we seek to remove the data as soon as possible.
    </p>
    <h1>
      VI. How America America preserves and protects your personal data
    </h1>
    <p>
      In principle, personal information collected and generated within the
      territory of China will be stored within the territory of China.In
      principle, personal information collected and generated within the
      territory of China will be stored within the territory of China.America
      and Asia values the security of personal data.We will retain your personal
      data for the period required for the purposes stated in this policy unless
      required or permitted by law.The storage period of data may vary based on
      different scenarios and products and services.Meia is not liable for
      security omissions caused by third party access to your personal
      information by your failure to keep your personal information
      private.Notwithstanding the above provisions, you should immediately
      notify us of any other Internet user's unauthorized use of your account or
      any other security breach.Your assistance will help us to protect the
      privacy of your personal information.
    </p>
    <h1>
      VII. Third-party providers and their services
    </h1>
    <p>
      To ensure a smooth browsing experience, you may receive content or network
      links from third parties (hereinafter "third parties") outside of America
      and its partners.Asia has no control over such third parties.You can
      choose whether to access the links, content, products, and services
      provided by third parties.Meiya cannot control the privacy and data
      protection policies of third parties, which are not subject to this
      policy.See the privacy policies for these third parties before submitting
      personal information to third parties.
    </p>
    <h1>
      VIII. How to update this policy
    </h1>
    <p>
      Asia reserves the right to update or amend this policy from time to
      time.If our privacy policy changes, we will release the latest version of
      our privacy policy here.Most recently updated date: September 2021
    </p>
  </div>
</template>

<script></script>

<style scoped>
h1 {
  margin-top: 1em;
  font-size: 1.4rem;
}
h2 {
  margin-top: 0.5em;
  font-size: 1.2rem;
}
p {
  margin-top: 0.3em;
}
div {
  padding: 0.8rem;
}
* {
  line-height: 1.5;
}
</style>
